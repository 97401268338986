<!--
 * @Autor: Nehc
 * @Date: 2020-11-26 19:27:49
 * @LastEditors: Nehc
 * @LastEditTime: 2020-11-27 09:42:27
-->
<template>
  <div class="home">
    <TablePage />
  </div>
</template>

<script>
import TablePage from './table';

export default {
  name: 'AvailRule',
  components: {
    TablePage,
  },
};
</script>
