<template>
  <div class="list-box">
    <!-- 组织（客户） -->
    <div v-if="type === '0'">
      <!-- <div v-if="ruleType === '0'"> -->
      <vxe-toolbar v-if="notView">
        <template v-slot:buttons>
          <vxe-button @click="addTable('orgCus')">添加组织</vxe-button>
          <vxe-button @click="deleteTable('orgCus')">删除</vxe-button>
        </template>
      </vxe-toolbar>
      <vxe-table
        :align="allAlign"
        @checkbox-all="
          ({ records }) => selectAllEvent('orgCusSelect', records)
        "
        @checkbox-change="
          ({ records }) => selectChangeEvent('orgCusSelect', records)
        "
        :data="orgCusTableData"
      >
        <vxe-table-column type="checkbox" width="50"></vxe-table-column>
        <vxe-table-column
          field="orgCode"
          title="组织编码"
        ></vxe-table-column>
        <vxe-table-column
          field="orgName"
          title="组织名称"
        ></vxe-table-column>
      </vxe-table>
    </div>
    <!-- 组织（终端） -->
    <div v-if="type === '2'">
      <vxe-toolbar v-if="notView">
        <template v-slot:buttons>
          <vxe-button @click="addTable('orgTerm')">添加组织</vxe-button>
          <vxe-button @click="deleteTable('orgTerm')">删除</vxe-button>
        </template>
      </vxe-toolbar>
      <vxe-table
        :align="allAlign"
        @checkbox-all="
          ({ records }) => selectAllEvent('orgTermSelect', records)
        "
        @checkbox-change="
          ({ records }) => selectChangeEvent('orgTermSelect', records)
        "
        :data="orgTermTableData"
      >
        <vxe-table-column type="checkbox" width="50"></vxe-table-column>
        <vxe-table-column field="orgCode" title="组织编码"></vxe-table-column>
        <vxe-table-column field="orgName" title="组织名称"></vxe-table-column>
      </vxe-table>
    </div>
    <!-- 客户 -->
    <div v-if="type === '1'">
      <vxe-toolbar v-if="notView">
        <template v-slot:buttons>
          <vxe-button @click="addTable('cus')">添加客户</vxe-button>
          <vxe-button @click="deleteTable('cus')">删除</vxe-button>
        </template>
      </vxe-toolbar>
      <vxe-table
        :align="allAlign"
        @checkbox-all="
          ({ records }) => selectAllEvent('cusSelect', records)
        "
        @checkbox-change="
          ({ records }) => selectChangeEvent('cusSelect', records)
        "
        :data="cusTableData"
      >
        <vxe-table-column type="checkbox" width="50"></vxe-table-column>
        <vxe-table-column
          field="customerCode"
          title="客户编码"
        ></vxe-table-column>
        <vxe-table-column
          field="customerName"
          title="客户名称"
        ></vxe-table-column>
        <vxe-table-column
          field="customerOrgName"
          title="所属组织"
        ></vxe-table-column>
        <vxe-table-column
          field="channelName"
          title="渠道类型"
        ></vxe-table-column>
      </vxe-table>
    </div>
    <!-- 终端 -->
    <div v-if="type === '3'">
      <vxe-toolbar v-if="notView">
        <template v-slot:buttons>
          <vxe-button @click="addTable('term')">添加终端</vxe-button>
          <vxe-button @click="deleteTable('term')">删除</vxe-button>
        </template>
      </vxe-toolbar>
      <vxe-table
        :align="allAlign"
        @checkbox-all="
          ({ records }) => selectAllEvent('termSelect', records)
        "
        @checkbox-change="
          ({ records }) => selectChangeEvent('termSelect', records)
        "
        :data="termTableData"
      >
        <vxe-table-column type="checkbox" width="50"></vxe-table-column>
        <vxe-table-column
          field="terminalCode"
          title="终端编码"
        ></vxe-table-column>
        <vxe-table-column
          field="terminalName"
          title="终端名称"
        ></vxe-table-column>
        <vxe-table-column field="orgName" title="所属组织"></vxe-table-column>
        <vxe-table-column
          field="channelName"
          title="渠道类型"
        ></vxe-table-column>
      </vxe-table>
    </div>
    <h5>{{isAvail ? '' : '不'}}可购商品</h5>
    <ProductArea :isView="!notView" :value="value"/>
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import SelectConfig from '../../../../../../../components/select_org_cus_ter/select_config.vue';
import ProductArea from './product_area.vue';

export default {
  name: 'DimensionAndAvail',
  components: {
    SelectConfig,
    ProductArea,
  },
  props: {
    value: Object,
    type: String,
    notView: Boolean,
    isAvail: Boolean,
  },
  watch: {
    type: {
      deep: true,
      immediate: true,
      handler(v) {
        console.log(v);
      },
    },
    value() {
      this.orgCusTableData = this.value.cusOrg;
      this.orgTermTableData = this.value.termOrg;
      this.cusTableData = this.value.cus;
      this.termTableData = this.value.term;
    },
  },
  data() {
    return {
      modalConfig: {}, // 弹框信息
      allAlign: null,
      orgCusTableData: [], // 客户（组织）
      orgTermTableData: [], // 客户（终端）
      cusTableData: [], // 客户
      termTableData: [], // 终端
      goodsTableData: [], // 商品
      modalType: '', // 列表区分的值
      // 对应的行选中
      goodsSelect: [],
      termSelect: [],
      cusSelect: [],
      orgTermSelect: [],
      orgCusSelect: [],
    };
  },
  methods: {
    // 弹窗选中新增到列表
    addTable(val) {
      this.modalType = val;
      let params;
      if (this.modalType === 'orgCus') {
        const selectionList = [];
        const list = this.orgCusTableData;
        list.forEach((v) => {
          selectionList.push(v.customerOrgCode);
        });
        params = {
          // functionCode: 'dms_addOrg_customer',
          functionCode: 'dms_addOrg_terminmal',
          data: list,
          selectionList,
          paramData: {
            enableStatus: '009',
          },
          idKey: 'orgCode',
        };
      } else if (this.modalType === 'orgTerm') {
        const selectionList = [];
        console.log(this.orgTermTableData);
        const list = this.orgTermTableData;
        list.forEach((v) => {
          selectionList.push(v.orgCode);
        });
        params = {
          functionCode: 'dms_addOrg_terminmal',
          data: list,
          selectionList,
          paramData: {
            enableStatus: '009',
          },
          idKey: 'orgCode',
        };
      } else if (this.modalType === 'cus') {
        const selectionList = [];
        const list = this.cusTableData;
        list.forEach((v) => {
          selectionList.push(v.customerCode);
        });
        params = {
          functionCode: 'dms_ruleAdd_cus',
          data: list,
          selectionList,
          idKey: 'customerCode',
        };
      } else if (this.modalType === 'term') {
        const selectionList = [];
        const list = this.termTableData;
        list.forEach((v) => {
          selectionList.push(v.terminalCode);
        });
        params = {
          functionCode: 'dms_ruleAdd_term',
          data: list,
          selectionList,
          idKey: 'terminalCode',
        };
      }
      this.$refs.selectConfig.openSelectModal(params);
    },
    // 获取弹窗内选中
    onGetSelect(val) {
      if (this.modalType === 'orgCus') {
        this.value.cusOrg = val;
        this.orgCusTableData = val;
      } else if (this.modalType === 'orgTerm') {
        this.value.termOrg = val;
        this.orgTermTableData = val;
      } else if (this.modalType === 'cus') {
        this.value.cus = val;
        this.cusTableData = val;
      } else if (this.modalType === 'term') {
        this.value.term = val;
        this.termTableData = val;
      }
    },
    // 行删除
    deleteTable(val) {
      this.modalType = val;
      const list = [];
      let code = '';
      switch (this.modalType) {
        case 'orgCus': {
          code = 'orgCode';
          break;
        }
        case 'orgTerm': {
          code = 'orgCode';
          break;
        }
        case 'cus': {
          code = 'customerCode';
          break;
        }
        case 'term': {
          code = 'terminalCode';
          break;
        }
        default: {
          break;
        }
      }
      this[`${val}TableData`].forEach((v) => {
        let notSelect = true;
        if (this[`${val}Select`].filter((a) => a[code] === v[code]).length > 0) {
          notSelect = false;
        }
        if (notSelect) {
          list.push(v);
        }
      });
      this[`${val}TableData`] = list;
      this[`${val}Select`] = [];
      if (this.modalType === 'orgCus') {
        this.value.cusOrg = this.orgCusTableData;
      } else if (this.modalType === 'orgTerm') {
        this.value.termOrg = this.orgTermTableData;
      } else if (this.modalType === 'cus') {
        this.value.cus = this.cusTableData;
      } else if (this.modalType === 'term') {
        this.value.term = this.termTableData;
      }
    },
    selectChangeEvent(val, records) {
      // console.log(val, records);
      this[val] = records;
    },
    selectAllEvent(val, records) {
      this[val] = records;
    },
    // 打开弹窗
    openModal() {
      this.modalConfig = {
        ...this.modalConfig,
        ...{ visible: true, type: 'Modal' },
      };
    },
    // 关闭弹窗
    closeModal() {
      this.modalConfig = { ...this.modalConfig, visible: false };
    },
  },
};
</script>

<style lang="less" scoped>
</style>
