<!--
 * @Autor: Nehc
 * @Date: 2020-11-26 19:43:52
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-22 15:03:15
-->
<script>
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
// import configs from './data';
import Form from '../form';

export default {
  extends: TablePage,
  components: {
    Modal,
    Form,
  },
  data() {
    return {
      requestUrl: '/mdm/m/availableList/rule/list',
      // configs,
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  async created() {
    // this.getConfigList('dms_rule_avail');
    const columns = await this.getConfigList('dms_rule_avail');
    columns.map((v) => {
      const rowData = v;
      if (rowData.field === 'createDate') {
        rowData.formatter = ({ row }) => `${row.createDate} ${row.createDateSecond}`;
      }
      if (rowData.field === 'updateDate') {
        rowData.formatter = ({ row }) => (row.updateDate == null ? '' : `${row.updateDate} ${row.updateDateSecond}`);
      }
      if (rowData.field === 'updateName') {
        rowData.formatter = ({ row }) => (row.updateName == null ? '' : `${row.updateName}`);
      }
      return rowData;
    });
  },
  methods: {
    modalClick({ val, row }) {
      this.formName = 'Form';
      if (val.code === 'edit') {
        this.modalConfig.title = '编辑';
        this.formConfig = {
          ...val,
          ...row,
          edit: 'isEdit',
          code: 'edit',
        };
        this.formConfig.selection = [row.id];
        this.openFull();
      } else if (val.code === 'view') {
        this.modalConfig.title = '查看';
        this.formConfig = {
          ...val,
          rows: { ...row },
          code: this.formConfig.code,
        };
        this.formConfig.selection = [row.id];
        this.openFull();
      } else if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.formConfig.selection = [];
        this.formConfig.code = 'add';
        this.openFull();
      }
      return true;
    },
  },
};
</script>
