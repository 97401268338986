<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

import DimensionAndAvail from './components';

formCreate.component('DimensionAndAvail', DimensionAndAvail);

export default {
  extends: Form,
  data() {
    return {
      rule: [],
      dimen: '',
      disableComponents: [],
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  async mounted() {
    this.rule = await this.getFormRule('dms_rule_add');
    this.rule.map((v) => {
      const rowData = v;
      if (rowData.field === 'code') {
        rowData.validate = [
          ...rowData.validate,
          {
            trigger: 'blur',
            pattern: '^[a-zA-Z0-9]*$',
            message: '仅能输入英文和数字的组合',
          },
        ];
      }
      if (rowData.field === 'list') {
        rowData.props = {
          ...rowData.props,
          functionCode: '',
          selection: [],
          idKey: 'id',
          type: '',
          notView: this.formConfig.code !== 'view',
          isAvail: true,
        };

        rowData.value = {
          cusOrg: [],
          termOrg: [],
          cus: [],
          term: [],
          product: [],
          productLevel: [],
        };
      }
      if (rowData.field === 'type') {
        rowData.on = {
          ...rowData.on,
          change: (val) => {
            this.getRule('list').props.isAvail = val === '1';
          },
        };
      }
      return rowData;
    });
    // this.rule.push({
    //   type: 'DimensionAndAvail',
    //   field: 'list',
    //   title: '',
    //   props: {
    //     functionCode: '',
    //     selection: [],
    //     idKey: 'id',
    //     type: '',
    //     notView: this.formConfig.code !== 'view',
    //   },
    //   value: {
    //     cusOrg: [],
    //     termOrg: [],
    //     cus: [],
    //     term: [],
    //     goods: [],
    //   },
    // });
    this.reload(this.rule);
    if (this.formConfig.selection[0]) {
      this.getData();
    }
    // 改变维度onchange事件
    this.getRule('dimension').on.change = this.dimensionChange;
    // 编辑控制不可见性
    if (this.formConfig.edit === 'isEdit') {
      this.disableComponents.push('dimension', 'code');
    }
    this.disableComponents.map((item) => {
      this.getRule(item).props.disabled = true;
      return true;
    });
  },
  methods: {
    dimensionChange(val) {
      this.getRule('list').props.type = val;
    },
    getData() {
      if (this.formConfig.code === 'view') {
        this.getRule('list').props.type = this.formConfig.rows.dimension.toString();
        this.dimen = this.formConfig.rows.dimension;
      } else {
        this.getRule('list').props.type = this.formConfig.dimension.toString();
        this.dimen = this.formConfig.dimension;
      }
      request
        .get(
          `/mdm/m/availableList/rule/findById/${this.formConfig.selection[0]}`,
          {
            id: this.formConfig.selection[0],
          },
        )
        .then((res) => {
          res.result.goods.map((v) => {
            const rowData = v;
            rowData.productCode = v.goodsCode;
            rowData.productName = v.goodsName;
            rowData.productLevelCode = v.productLevelCode;
            rowData.spec = v.productSpec;
            delete rowData.id;
            return rowData;
          });
          const cusOrgData = [];
          const termOrgData = [];
          const cusData = [];
          const termData = [];
          switch (Number(this.dimen)) {
            // 组织（客户）
            case 0: {
              res.result.areaes.map((v) => {
                cusOrgData.push({
                  orgCode: v.orgCode,
                  orgName: v.orgName,
                });
                return v;
              });
              break;
            }
            // 客户
            case 1: {
              res.result.areaes.map((v) => {
                cusData.push({
                  customerName: v.cusName,
                  customerCode: v.cusCode,
                  customerOrgName: v.cusOrgName,
                  customerOrgCode: v.cusOrgCode,
                  channelName: v.cusChannelName,
                });
                return v;
              });
              break;
            }
            // 组织（终端）
            case 2: {
              res.result.areaes.map((v) => {
                termOrgData.push({
                  orgCode: v.orgCode,
                  orgName: v.orgName,
                });
                return v;
              });
              break;
            }
            // 终端
            case 3: {
              res.result.areaes.map((v) => {
                termData.push({
                  terminalName: v.terminalName,
                  terminalCode: v.terminalCode,
                  orgName: v.terminalOrgName,
                  channelName: v.terminalChannelName,
                  channel: v.terminalChannelCode,
                });
                return v;
              });
              break;
            }
            default: {
              break;
            }
          }
          const product = [];
          const productLevel = [];
          res.result.goods.forEach((v) => {
            if (v.productLevelFlag === 'Y') {
              productLevel.push(v);
            } else {
              product.push(v);
            }
          });
          const data = {
            ...res.result,
            code: res.result.code,
            name: res.result.name,
            dimension: `${res.result.dimension}`,
            type: `${res.result.type}`,
            list: {
              cusOrg: cusOrgData,
              termOrg: termOrgData,
              cus: cusData,
              term: termData,
              product,
              productLevel,
            },
          };
          this.getRule('list').props.isAvail = `${res.result.type}` === '1';
          this.setValue(data);
        });
    },
    // 提交
    async submit() {
      const index = this.getFormData().dimension;
      // console.log(index);
      const areaes = [];
      switch (Number(index)) {
        // 组织（客户）
        case 0: {
          this.getFormData().list.cusOrg.map((v) => {
            areaes.push({
              orgCode: v.orgCode,
              orgName: v.orgName,
            });
            return v;
          });
          // console.log('111===>>>', areaes);
          break;
        }
        // 客户
        case 1: {
          this.getFormData().list.cus.map((v) => {
            areaes.push({
              cusName: v.customerName,
              cusCode: v.customerCode,
              cusOrgCode: v.customerOrgCode,
              cusOrgName: v.customerOrgName,
              cusChannelCode: v.channel,
              cusChannelName: v.channelName,
            });
            return v;
          });
          break;
        }
        // 组织（终端）
        case 2: {
          this.getFormData().list.termOrg.map((v) => {
            areaes.push({
              orgCode: v.orgCode,
              orgName: v.orgName,
            });
            return v;
          });
          break;
        }
        // 终端
        case 3: {
          this.getFormData().list.term.map((v) => {
            areaes.push({
              terminalName: v.terminalName,
              terminalCode: v.terminalCode,
              terminalOrgCode: v.orgCode,
              terminalOrgName: v.orgName,
              terminalChannelCode: v.channel,
              terminalChannelName: v.channelName,
            });
            return v;
          });
          break;
        }
        default: {
          break;
        }
      }
      let requestUrl = '';
      if (this.formConfig.selection[0]) {
        requestUrl = '/mdm/m/availableList/rule/edit';
      } else {
        requestUrl = '/mdm/m/availableList/rule/add';
      }
      // console.log(this.getFormData());
      const goods = [];
      const { product, productLevel } = this.getFormData().list;
      product.forEach((v) => {
        const rowData = v;
        delete rowData.id;
        goods.push({
          ...rowData,
          goodsCode: rowData.productCode,
          goodsName: rowData.productName,
          productSpec: rowData.spec,
          productLevelCode: rowData.productLevelCode,
          productLevelName: rowData.productLevelName,
          productLevelFlag: 'N',
        });
      });
      productLevel.forEach((v) => {
        const rowData = v;
        delete rowData.id;
        goods.push({
          ...rowData,
          goodsCode: rowData.productCode,
          goodsName: rowData.productName,
          productSpec: rowData.spec,
          productLevelCode: rowData.productLevelCode,
          productLevelName: rowData.productLevelName,
          productLevelFlag: 'Y',
        });
      });
      const params = {
        id: this.formConfig.selection[0] || '',
        code: this.getFormData().code,
        dimension: this.getFormData().dimension,
        name: this.getFormData().name,
        type: this.getFormData().type,
        goods,
        // 商品信息
        // goods: this.getFormData().list.goods.map((v) => {
        //   const rowData = v;
        //   rowData.goodsCode = v.productCode;
        //   rowData.goodsName = v.productName;
        //   rowData.productSpec = v.spec;
        //   rowData.productLevelCode = v.productLevelCode;
        //   delete rowData.id;
        //   return rowData;
        // }),
        // 维度判断
        areaes,
      };
      // console.log(params);
      request.post(requestUrl, params).then((res) => {
        if (res.code === 200) {
          this.$emit('onClose');
          this.$emit('onGetList');
        } else {
          this.$message.error(res.messge);
        }
      });
    },
  },
};
</script>
