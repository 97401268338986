var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-box" },
    [
      _vm.type === "0"
        ? _c(
            "div",
            [
              _vm.notView
                ? _c("vxe-toolbar", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "buttons",
                          fn: function () {
                            return [
                              _c(
                                "vxe-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.addTable("orgCus")
                                    },
                                  },
                                },
                                [_vm._v("添加组织")]
                              ),
                              _c(
                                "vxe-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteTable("orgCus")
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2700563384
                    ),
                  })
                : _vm._e(),
              _c(
                "vxe-table",
                {
                  attrs: { align: _vm.allAlign, data: _vm.orgCusTableData },
                  on: {
                    "checkbox-all": function (ref) {
                      var records = ref.records

                      return _vm.selectAllEvent("orgCusSelect", records)
                    },
                    "checkbox-change": function (ref) {
                      var records = ref.records

                      return _vm.selectChangeEvent("orgCusSelect", records)
                    },
                  },
                },
                [
                  _c("vxe-table-column", {
                    attrs: { type: "checkbox", width: "50" },
                  }),
                  _c("vxe-table-column", {
                    attrs: { field: "orgCode", title: "组织编码" },
                  }),
                  _c("vxe-table-column", {
                    attrs: { field: "orgName", title: "组织名称" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.type === "2"
        ? _c(
            "div",
            [
              _vm.notView
                ? _c("vxe-toolbar", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "buttons",
                          fn: function () {
                            return [
                              _c(
                                "vxe-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.addTable("orgTerm")
                                    },
                                  },
                                },
                                [_vm._v("添加组织")]
                              ),
                              _c(
                                "vxe-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteTable("orgTerm")
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3259929944
                    ),
                  })
                : _vm._e(),
              _c(
                "vxe-table",
                {
                  attrs: { align: _vm.allAlign, data: _vm.orgTermTableData },
                  on: {
                    "checkbox-all": function (ref) {
                      var records = ref.records

                      return _vm.selectAllEvent("orgTermSelect", records)
                    },
                    "checkbox-change": function (ref) {
                      var records = ref.records

                      return _vm.selectChangeEvent("orgTermSelect", records)
                    },
                  },
                },
                [
                  _c("vxe-table-column", {
                    attrs: { type: "checkbox", width: "50" },
                  }),
                  _c("vxe-table-column", {
                    attrs: { field: "orgCode", title: "组织编码" },
                  }),
                  _c("vxe-table-column", {
                    attrs: { field: "orgName", title: "组织名称" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.type === "1"
        ? _c(
            "div",
            [
              _vm.notView
                ? _c("vxe-toolbar", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "buttons",
                          fn: function () {
                            return [
                              _c(
                                "vxe-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.addTable("cus")
                                    },
                                  },
                                },
                                [_vm._v("添加客户")]
                              ),
                              _c(
                                "vxe-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteTable("cus")
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      48576110
                    ),
                  })
                : _vm._e(),
              _c(
                "vxe-table",
                {
                  attrs: { align: _vm.allAlign, data: _vm.cusTableData },
                  on: {
                    "checkbox-all": function (ref) {
                      var records = ref.records

                      return _vm.selectAllEvent("cusSelect", records)
                    },
                    "checkbox-change": function (ref) {
                      var records = ref.records

                      return _vm.selectChangeEvent("cusSelect", records)
                    },
                  },
                },
                [
                  _c("vxe-table-column", {
                    attrs: { type: "checkbox", width: "50" },
                  }),
                  _c("vxe-table-column", {
                    attrs: { field: "customerCode", title: "客户编码" },
                  }),
                  _c("vxe-table-column", {
                    attrs: { field: "customerName", title: "客户名称" },
                  }),
                  _c("vxe-table-column", {
                    attrs: { field: "customerOrgName", title: "所属组织" },
                  }),
                  _c("vxe-table-column", {
                    attrs: { field: "channelName", title: "渠道类型" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.type === "3"
        ? _c(
            "div",
            [
              _vm.notView
                ? _c("vxe-toolbar", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "buttons",
                          fn: function () {
                            return [
                              _c(
                                "vxe-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.addTable("term")
                                    },
                                  },
                                },
                                [_vm._v("添加终端")]
                              ),
                              _c(
                                "vxe-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteTable("term")
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2931504796
                    ),
                  })
                : _vm._e(),
              _c(
                "vxe-table",
                {
                  attrs: { align: _vm.allAlign, data: _vm.termTableData },
                  on: {
                    "checkbox-all": function (ref) {
                      var records = ref.records

                      return _vm.selectAllEvent("termSelect", records)
                    },
                    "checkbox-change": function (ref) {
                      var records = ref.records

                      return _vm.selectChangeEvent("termSelect", records)
                    },
                  },
                },
                [
                  _c("vxe-table-column", {
                    attrs: { type: "checkbox", width: "50" },
                  }),
                  _c("vxe-table-column", {
                    attrs: { field: "terminalCode", title: "终端编码" },
                  }),
                  _c("vxe-table-column", {
                    attrs: { field: "terminalName", title: "终端名称" },
                  }),
                  _c("vxe-table-column", {
                    attrs: { field: "orgName", title: "所属组织" },
                  }),
                  _c("vxe-table-column", {
                    attrs: { field: "channelName", title: "渠道类型" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("h5", [_vm._v(_vm._s(_vm.isAvail ? "" : "不") + "可购商品")]),
      _c("ProductArea", { attrs: { isView: !_vm.notView, value: _vm.value } }),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }